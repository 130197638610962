import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'
import BlogEndpoint from '../../API/BlogAPI';
import QRCode from 'qrcode.react';

const Sidebar = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPosts() {
      try {
        const data = await BlogEndpoint.getPosts(); // Fetch posts from backend
        const sortedPosts = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by date
        const recentPosts = sortedPosts.slice(0, 10); // Get only the first 10 posts
        setPosts(recentPosts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    }

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!posts || posts.length === 0) {
    return <div>No posts available.</div>;
  }

  return (
    <aside className='sidebar-container'>
      <div className="content-tile sidebar-tile recent-posts">
        <h2>Recent Posts</h2>
        <div className="post-list">
          {posts.map(post => (
            <div key={post.id} className="post-item">
              <Link className="sidebar-link" to={`/post/${post.id}`}>{post.title}</Link>
            </div>
          ))}
        </div>
      </div>
      <div className="content-tile sidebar-tile">
        <h2>Scan Me!</h2>
        <div className='qr-container'>
          <div className='qr-code'>
            <a href={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} target="_blank" rel="noopener noreferrer">
            <QRCode value={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} size={128} />
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;